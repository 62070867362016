import {
    animate,
    createAnimatable,
    createDraggable,
    createTimer,
    utils,
} from "animejs";

export default ({ speed = 1, init = () => null, ...others } = {}) => ({
    ...others,
    carousel: {
        width: 0,
        speedX: speed,
        wheelX: 0,
        wheelY: 0,
    },
    animatable: null,
    draggable: null,
    timer: null,
    wheelDeltaAnim: null,
    getTotalWidth(total, $el) {
        const style = window.getComputedStyle($el);
        const marginsWidth =
            parseInt(style.marginLeft) + parseInt(style.marginRight);
        return total + $el.offsetWidth + marginsWidth;
    },
    onWheel(e) {
        e.preventDefault();
        this.carousel.wheelY = utils.lerp(this.carousel.wheelY, e.deltaY, 0.2);
        this.carousel.wheelX = utils.lerp(this.carousel.wheelX, e.deltaX, 0.2);
        this.wheelDeltaAnim.refresh().restart();
    },
    pause() {
        if (this.timer) {
            this.timer.pause();
        }
    },
    play() {
        if (this.timer) {
            this.timer.play();
        }
    },
    refreshWidth() {
        this.carousel.width = this.getItems().reduce(this.getTotalWidth, 0);
    },
    refresh() {
        this.refreshWidth();

        if (!this.timer) {
            this.initCarousel();
        } else if (this.carousel.width <= this.$el.offsetWidth) {
            this.destroyCarousel();
        }
    },
    refreshDebounced: Alpine.debounce(function () {
        this.refresh();
    }, 100),
    getItems() {
        return Array.from(this.$refs.carousel.children);
    },
    initCarousel() {
        this.destroyCarousel();

        const $parent = this.$el;
        const $carousel = this.$refs.carousel;

        this.refreshWidth();

        if (this.carousel.width <= this.$el.offsetWidth) {
            return;
        }

        this.animatable = createAnimatable($carousel, {
            x: 0,
            modifier: (v) => utils.wrap(v, -this.carousel.width / 2, 0),
        });

        const { x } = this.animatable;

        this.draggable = createDraggable(this.carousel, {
            trigger: $parent,
            y: false,
            onGrab: () => animate(this.carousel, { speedX: 0, duration: 500 }),
            onRelease: () => {
                return animate(this.carousel, { speedX: speed, duration: 500 });
            },
            onResize: () => this.refresh(),
            releaseStiffness: 20,
        });

        this.timer = createTimer({
            onUpdate: () => {
                x(
                    x() -
                        this.carousel.speedX +
                        this.draggable.deltaX -
                        this.carousel.wheelX -
                        this.carousel.wheelY
                );
            },
        });

        // Support mousewheel
        this.wheelDeltaAnim = animate(this.carousel, {
            wheelY: 0, // We make sure the wheel delta always goes back to 0
            wheelX: 0, // We make sure the wheel delta always goes back to 0
            duration: 500,
            autoplay: false,
            ease: "out(4)",
        });
    },
    destroyCarousel() {
        if (this.wheelDeltaAnim) {
            this.wheelDeltaAnim.revert();
            this.wheelDeltaAnim = null;
        }

        if (this.timer) {
            this.timer.revert();
            this.timer = null;
        }

        if (this.draggable) {
            this.draggable.revert();
            this.draggable = null;
        }

        if (this.animatable) {
            this.animatable.revert();
            this.animatable = null;
        }
    },
    init() {
        this.initCarousel();
        init.call(this);
    },
    destroy() {
        this.destroyCarousel();
    },
});
